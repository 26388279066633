import React, { useState, Fragment, useEffect } from 'react';
import { User } from 'store/types/User';
import gold from 'image/icons/activity/collega-gold.svg';
import profile from 'image/icons/profile-placeholder.svg'
import ProfileItem, { ProfileSwitch } from './ProfileItem';
import ProfileWrapper from './ProfileWrapper';
import { ExternalLinks, DateFormat, FormFields } from 'values/values';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import { ActivityModal } from 'store/types/ActivityModal';
import { NavLink, useLocation } from 'react-router-dom';
import Network from 'network/Network';
import moment from 'moment';
import Utility from 'utils/utils';
import Storage from '../../store/Storage';
import strings from 'values/strings';
import { useSelector, useDispatch } from 'react-redux';
import { IUserReducer, createTempUser, updateTempUser, setCurrentUser } from 'store/reducers/UserReducer';
import UserService from 'services/UserServices';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import Modal from 'components/common/Modal';
import ProfileSkills from './ProfileSkills';
import BasicCoWorker from './BasicCoWorker';
import SpecificSkillsBanner from './SpecificSkillsBanner';

interface IProfile {
  currentUser: User;
}

const UserProfile: React.FC<IProfile> = ({ currentUser }) => {
  const [pass, setPass] = useState(false)
  const [certified, setCertified] = useState(false)
  const [cancelSubscriptionConfirm, setCancelSubscriptionConfirm] = useState(false)
  const [skillModal, setSkillModal] = useState(false)
  const [specificSkillModal, setSpecificSkillModal] = useState(false)
  const location = useLocation()
  let [showSkillsButton, setShowSkillsButton] = useState<boolean>()
  const genericSkills = currentUser && currentUser.profile.skills.filter(skill => skill.is_main === true)
  let [updateResponse, updateError, updateRequest] = UserService.useUpdateUserAPI()
  let [cancelSubscriptionResponse, cancelSubscriptionError, cancelSubscriptionRequest] = UserService.useCancelSubscriptionAPI()

  const tempUser: User | any = useSelector((state: IUserReducer) => state.user.tempUser);
  const [skillsResponse, , skillsRequest] = UserService.useSearchSkillsAPI();
  const dispatch = useDispatch();
  let [isLoading, setIsLoading] = useState(true)

  const certifiedType: ActivityModal = {
    certified:
      <div className={'card certified__container _modal'}>
        <img src={gold} alt="collega" />
        <div>
          <h3>{strings.userProfile.modal.profile}</h3>
          <p>{strings.userProfile.modal.certified} <b>{strings.userProfile.modal.collega}</b></p>
        </div>
      </div>
    ,
    copy: currentUser!.last_payment ? Utility.format(
      currentUser!.last_payment.renewal ? strings.userProfile.modal.expirationDescription : strings.userProfile.modal.expirationDescriptionEnd,
      moment(currentUser!.last_payment!.expire_at).format(DateFormat.profile),
      currentUser!.last_payment!.payment_type!.price,
    ) : undefined,

    cta: currentUser!.last_payment && currentUser!.last_payment!.renewal ? strings.userProfile.modal.cancelSubscription : undefined,
    ctaUndo: strings.userProfile.modal.cta,
    onConfirm: currentUser!.last_payment && currentUser!.last_payment!.renewal ? (() => {
      setCertified(false)
      setCancelSubscriptionConfirm(true)
    }) : undefined,
    onCancel: () => setCertified(false)
  }

  const cancelSubscriptionModal: ActivityModal = {
    copy: strings.userProfile.popup.text,
    cta: strings.userProfile.popup.action,
    ctaUndo: strings.userProfile.popup.close,
    onConfirm: (() => {
      setCancelSubscriptionConfirm(false)
      cancelSubscriptionRequest(currentUser!.last_payment!.id)
    }),
    onCancel: () => setCancelSubscriptionConfirm(false)
  }

  useEffect(() => {
    skillsRequest('');
  }, []);

  useEffect(() => {
    if (skillsResponse) setIsLoading(false);
  }, [skillsResponse]);

  useEffect(() => {
    if (location.state && location.state.showSkillModal) {
      setSkillModal(true);
    }
  }, [location.state]);

  let onCertified = () => {
    AnalyticsService.screenName(AnalyticsScreens.profileCertification)

    let userID = new Buffer(String(currentUser.id!)).toString('base64')
    let createdAt = new Buffer(currentUser.created_at!).toString('base64')
    let url = Network.baseURL + ExternalLinks.PaymentStart + userID + '/' + createdAt + '/'
    window.open(url, "_blank")
  }

  const areSkills = currentUser.profile.skills.length > 0

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.profile)
  }, [])

  useEffect(() => {
    AnalyticsService.screenName(cancelSubscriptionConfirm ? AnalyticsScreens.popupCancelSubscription : AnalyticsScreens.profile)
  }, [cancelSubscriptionConfirm])

  useEffect(() => {
    if (certified) AnalyticsService.screenName(AnalyticsScreens.profileCertified)
  }, [certified])

  useEffect(() => {
    Storage.get(Storage.SHOW_SKILLS_BUTTONS).then((value: boolean) => {
      setShowSkillsButton(value === undefined ? true : value)
    }).catch(() => {
      return setShowSkillsButton(true)
    })
  })

  useEffect(() => {
    if (cancelSubscriptionResponse) {

      let user: any = {
        ...currentUser,
        last_payment: cancelSubscriptionResponse
      }

      dispatch(setCurrentUser(user))
    }
  }, [cancelSubscriptionResponse])

  useEffect(() => {
    dispatch(createTempUser(currentUser))
  }, [currentUser])

  return (
    <>
      <div className={'profile__container'}>
        <h2>{strings.userProfile.title}</h2>
        <div className={'divider'}></div>

        <div className={'__profile__container'}>

          <div className={'card __profile'}>
            <span className={'name'}>{User.fullName(currentUser)}</span>

            <div className={'role__container noSkills'}>
              <span className={'role'}>{Utility.localised(currentUser.profile.type)}</span>
            </div>

            {/* {areSkills && <Fragment>
            <span className={'skills'}>{strings.userProfile.skills}</span>
            <div className={'skills__container'}>
              {currentUser.profile.skills.map((skill: Skill, index) => {
                return <span key={index} className={'label skill'}>{skill.name}</span>
              })}
            </div>
          </Fragment>} */}

            <div className={'panel__icon shadow'}>
              <img src={currentUser.profile.picture ? currentUser.profile.picture : profile} alt={'profile'} />
            </div>

            {currentUser.is_certified && <div className={'img_badge'}></div>}
          </div>


          <BasicCoWorker currentUser={currentUser} />

          <SpecificSkillsBanner currentUser={currentUser} />

          {!currentUser.is_certified && <div className={'card certify__container'}>
            <div className={'element'}>
              <div className={'element__img'}>
                <img src={gold} alt="collega" />
                <h3>{strings.userProfile.toCertify.title}</h3>
              </div>
              <p dangerouslySetInnerHTML={{ __html: strings.userProfile.toCertify.subtitle }}></p>
              {/* <p>{strings.userProfile.toCertify.subtitle}</p> */}
              <div className={'button__'}>
                <button className={'button-primary'} onClick={onCertified}>{strings.userProfile.toCertify.cta}</button>
              </div>
            </div>
          </div>}

          {/* {currentUser.is_certified && !currentUser.profile.is_complete && <div className={'card complete__container'}>
          <div className={'element'}>
            <div>
              <h3>{strings.userProfile.toComplete.title}</h3>
              <p>{strings.userProfile.toComplete.subtitle}</p>
            </div>
            <NavLink to={'/profile-complete'}>
              <button className={'button-primary'} onClick={() => AnalyticsService.event(AnalyticsEvents.profileCompleteClicked, { email: currentUser!.email })} >{strings.userProfile.toComplete.cta}</button>
            </NavLink>
          </div>
        </div>} */}

          {currentUser.is_certified && showSkillsButton && !currentUser.profile.lawfirm.city2 &&
            <div className={'card certify__container'}>
              <div className={'element'}>
                <div className={'element__img'}>
                  <img src={gold} alt="collega" />
                  <h3>{strings.userProfile.isCertified.title}</h3>
                </div>

                <p>{strings.userProfile.isCertified.subtitle}</p>
                <div className={'button__ double'}>

                  {/* {showSkillsButton && <NavLink to={'/profile-skills'}>
                  <button className={'button-primary'} >{strings.userProfile.isCertified.cta}</button>
                </NavLink>} */}

                  {!currentUser.profile.lawfirm.city2 && <NavLink to={'/profile-locations'}>
                    <button className={'button-primary'} >{strings.userProfile.isCertified.cta2}</button>
                  </NavLink>}

                </div>
              </div>
            </div>
          }

          {currentUser.is_certified &&
            <div className={'card certified__container'} onClick={currentUser.last_payment ? () => setCertified(true) : undefined}>
              <img src={gold} alt="collega" />
              <div>
                <h3>{strings.userProfile.certified.title}</h3>
                <p>{strings.userProfile.certified.subtitle}<b>{strings.userProfile.certified.subtitle2}</b> fino al <b>{moment(currentUser.last_payment ? currentUser.last_payment.expire_at : currentUser.trial_expires_at).format(DateFormat.full)}</b></p>
              </div>
            </div>
          }

          <div className={'card info__container'}>
            <ProfileWrapper title={strings.profileEdit.step.personal.userTitle} cta={true} is_complete={currentUser && currentUser.profile.is_complete}>
              <ProfileItem label={strings.fieldLabelEmailSummary} content={currentUser.email} />
              {/* <ProfileItem label={strings.fieldLabelPasswordSummary} content={pass ? strings.profileEdit.step.personal.pass : strings.profileEdit.step.personal.passhide} hide={<span className={pass ? 'unhide hide' : 'unhide'} onClick={() => setPass(!pass)}></span>} /> */}
              <ProfileItem label={strings.fieldLabelPhoneSummary} content={currentUser.phone} />
              {currentUser.birth_date && <ProfileItem label={strings.profileEdit.step.personal.birth} content={moment(currentUser.birth_date).format(DateFormat.full)} />}
              {currentUser.fiscal_code && <ProfileItem label={strings.fieldLabelFiscalCodeSummary} content={currentUser.fiscal_code} />}
            </ProfileWrapper>

            <ProfileWrapper title={strings.profileEdit.step.professional.userTitle}>
              <ProfileItem label={strings.profileEdit.step.professional.place} content={currentUser.profile.city!.name} />
              {currentUser.profile.vat && <ProfileItem label={strings.profileEdit.step.professional.input.vat.label} content={currentUser.profile.vat} />}
              {currentUser.profile.pec && <ProfileItem label={strings.profileEdit.step.professional.input.pec.label} content={currentUser.profile.pec} />}
              {currentUser.profile.register_number && <ProfileItem label={strings.profileEdit.step.professional.input.register_number.label} content={currentUser.profile.register_number} />}
              {currentUser.profile.coa_office && <ProfileItem label={strings.profileEdit.step.professional.input.coa.label} content={currentUser.profile.coa_office.name} />}
            </ProfileWrapper>

            <ProfileWrapper title={strings.profileEdit.step.lawfirm.userTitle}>
              <ProfileItem label={strings.profileEdit.step.lawfirm.input.name.label} content={currentUser.profile.lawfirm.name} />
              <ProfileItem label={strings.profileEdit.step.lawfirm.input.city.label} content={currentUser.profile.lawfirm.city!.name} />
              {currentUser.profile.lawfirm.address && <ProfileItem label={strings.profileEdit.step.lawfirm.input.address.label} content={currentUser.profile.lawfirm.address} />}
              {currentUser.profile.lawfirm.zip && <ProfileItem label={strings.profileEdit.step.lawfirm.input.cap.label} content={currentUser.profile.lawfirm.zip} />}
              {currentUser.profile.lawfirm.phone && <ProfileItem label={strings.profileEdit.step.lawfirm.input.phone.label} content={currentUser.profile.lawfirm.phone} />}
              {currentUser.profile.lawfirm.fax && <ProfileItem label={strings.profileEdit.step.lawfirm.input.fax.label} content={currentUser.profile.lawfirm.fax} />}
            </ProfileWrapper>

            {currentUser.profile.lawfirm.city2 && <ProfileWrapper title={strings.profileEdit.step.lawfirm.input.city2.label}>
              <ProfileItem label={strings.profileEdit.step.lawfirm.input.city2.userLabel} content={currentUser.profile.lawfirm.city2.name} />
            </ProfileWrapper>}
          </div>

          <div className={'card info__container'}>
            <ProfileWrapper title={strings.userProfile.settings.title}>
              <ProfileSwitch
                content={strings.userProfile.settings.email}
                checked={tempUser && tempUser.email_enabled}
                onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.checked
                  if (value !== undefined)
                    await dispatch(updateTempUser(FormFields.email_enabled, value))
                  updateRequest({ ...tempUser, email_enabled: value })
                }}
              />
              <ProfileItem content={''} />
              <ProfileSwitch
                content={strings.userProfile.settings.notification}
                checked={tempUser && tempUser.notifications_enabled}
                onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.checked
                  if (value !== undefined)
                    await dispatch(updateTempUser(FormFields.notifications_enabled, value))
                  updateRequest({ ...tempUser, notifications_enabled: value })
                }}
              />
            </ProfileWrapper>
          </div>

          <div className={'card info__container'}>
            <ProfileWrapper title={strings.userProfile.terms.title}>
              <ProfileItem content={strings.userProfile.terms.terms} terms={ExternalLinks.Terms} />
              <ProfileItem content={''} />
              <ProfileItem content={strings.userProfile.terms.privacy} terms={ExternalLinks.Privacy} />
            </ProfileWrapper>
          </div>
          <div className={'button__container'}>
            <NavLink to={'/profile-edit'}>
              <button className={'button-secondary'}>{strings.userProfile.cta}</button>
            </NavLink>
          </div>
        </div>
        {certified && <ConfirmModal content={certifiedType} />}
        {cancelSubscriptionConfirm && <ConfirmModal content={cancelSubscriptionModal} />}

      </div>

      {skillModal && !isLoading &&
        <Modal onClose={() => setSkillModal(false)}>
          <ProfileSkills maxCheck={1} type='basic' allSkills={skillsResponse} />
        </Modal>
      }
    </>
  );
};

export default UserProfile;