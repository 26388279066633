import React from 'react';
import edit from '../../image/edit.svg';
import { useHistory } from 'react-router-dom';
interface Props {
  title: string
  cta?: boolean
  is_complete?: boolean
}
const ProfileWrapper: React.FC<Props> = ({ title, children, cta, is_complete }) => {
  const history = useHistory()
  
  return (
    <>
      <div className={'title-container'}>
        <span className={'title'}>{title}</span>
        {cta &&
          <>
            {!is_complete && <div className={'complete-notification'}/>}
            <img src={edit} alt="editIcon" onClick={() => history.push('profile-edit')}/>
          </>
        }
      </div>
      <div className={'info'}>
        {children}
      </div>
    </>
  );
};

export default ProfileWrapper;
