import React, { useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import WebFont from 'webfontloader';
import { registerLocale } from 'react-datepicker';
// tslint:disable-next-line:no-implicit-dependencies no-submodule-imports
import it from 'date-fns/locale/it';
import * as firebase from 'firebase/app';
import 'firebase/analytics';


import reducers from './store/reducers';

import AppRouter from './navigation/Router';

registerLocale('it', it);

WebFont.load({
  google: {
    families: ['Roboto', 'sans-serif']
  },
  custom: {
    families: ['SF UI Display', 'helvetica'],
    urls: ['./src/fonts/SFUI/font.css', './src/fonts/helvetica/font.css']
  }
});

const App: React.FC = () => {

  // const composeEnhancers = (__DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
  //   compose;

  const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

  useEffect(() => {

    let firebaseConfig = {
      apiKey: "AIzaSyCQfQVUguGl29Mo4bhmuAmkbLeIgMWI0oM",
      authDomain: "collega-2019.firebaseapp.com",
      databaseURL: "https://collega-2019.firebaseio.com",
      projectId: "collega-2019",
      storageBucket: "collega-2019.appspot.com",
      messagingSenderId: "260222047718",
      appId: "1:260222047718:web:96240fc1aa202764487227",
      measurementId: "G-V5Y1BD7HPC"
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

  }, [])

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};


export default App;
