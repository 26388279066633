import React, { useState, useEffect } from 'react';
import arrow from '../../image/icons/arrow-left-white.svg';
import gold from 'image/icons/activity/collega-gold.svg';
import modalGirl from 'image/icons/activity/modalGirl.svg'
import profile from 'image/icons/profile-placeholder.svg'
import certificate from 'image/icons/activity/certificate.svg'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentCandidateUser, ActivitiesReducer, setShouldPerformRequest, setActivityDemandsTab, setCurrentDemand, setCurrentCandidate } from '../../store/reducers/ActivitiesReducer';
import { CandidatesStatus, DemandsStatus } from 'values/values';
import ActivitiesService from 'services/ActivitiesService';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import { ActivityModal } from 'store/types/ActivityModal';
import { User } from 'store/types/User';
import strings from 'values/strings';
import Utility from 'utils/utils';
import AnalyticsService, { AnalyticsScreens, AnalyticsEvents } from 'services/AnalyticsService';
import _ from 'lodash';


const ActivityRightProfile: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const currentCandidateUser = useSelector((state: ActivitiesReducer) => state.activitiesReducer.currentCandidateUser);
  const currentDemand = useSelector((state: ActivitiesReducer) => state.activitiesReducer.currentDemand);
  const currentCandidate = useSelector((state: ActivitiesReducer) => state.activitiesReducer.currentCandidate);
  const [open, setOpen] = useState<boolean>(false)
  const [opened, setOpened] = useState(false)
  const [certified, setCertified] = useState(false)

  let dispatch = useDispatch()

  let [assignResponse, assignError, assignRequest] = ActivitiesService.useAssignDemandAPI()


  useEffect(() => {
    if (open) AnalyticsService.screenName(AnalyticsScreens.profilePublic)
  }, [open])

  useEffect(() => {
    if (assignResponse) {
      setIsLoading(false)
      setOpened(true)
    }
  }, [assignResponse])

  const assignedType = (): ActivityModal => {
    return {
      img: modalGirl,
      title: strings.activityProfile.modal.assigned.title,
      copy: strings.activityProfile.modal.assigned.copy + User.fullName(currentCandidate!.user!) + '. ' + currentCandidate!.user!.first_name + strings.activityProfile.modal.assigned.copy2,
      cta: strings.activityProfile.modal.assigned.cta,
      ctaUndo: strings.activityProfile.modal.assigned.ctaUndo,
      onConfirm: () => {
        setOpened(false)
        dispatch(setActivityDemandsTab(DemandsStatus.ASSIGNED))
        dispatch(setCurrentDemand(undefined))
        dispatch(setCurrentCandidateUser(undefined))
        dispatch(setCurrentCandidate(undefined))
      },
      onCancel: () => { setOpened(false); dispatch(setShouldPerformRequest(true)) },
    }
  }


  const certifiedType: ActivityModal =
  {
    certified:
      <div className={'certified__container _modal'} >
        <img src={gold} alt="collega" />
        <div className={'text'}>
          <span className={'title'}>{strings.userProfile.modal.profile}</span>
          <span className={'subtitle'}>{strings.userProfile.modal.certified} <b>{strings.userProfile.modal.collega}</b></span>
        </div>
      </div>,
    copy: strings.activityProfile.modal.certified.copy,
    cta: strings.activityProfile.modal.certified.cta,
    onConfirm: () => setCertified(false),
  }

  useEffect(() => {
    if (currentCandidateUser) setOpen(true)
  }, [currentCandidateUser])

  const onToggleArrowClick = () => {
    setOpen(false)
    setTimeout(() => { dispatch(setCurrentCandidateUser(undefined)); }, 200);

  }

  if (!currentCandidateUser) { return null }
  if (!currentDemand) { return null }
  if (!currentCandidate) { return null }

  return (
    <div className={open ? 'activity__right__profile__container' : 'activity__right__profile__container closed'}>

      <div className={'close-right'} onClick={() => onToggleArrowClick()}>
        <img src={arrow} style={{ cursor: 'pointer' }} alt={'arrow'} />
      </div>
      <div className={'greenBg'}></div>
      <div className={currentDemand.status === CandidatesStatus.PENDING ? 'activity__right__profile cta' : 'activity__right__profile'}>
        <div className={'activity__profile__container'}>
          <span className={'name'}>{currentCandidateUser.first_name + ' ' + currentCandidateUser.last_name}</span>
          <div className="role__container">
            <span className={'role'}>{Utility.localised(currentCandidateUser.profile.type)}</span>
            {currentCandidateUser.is_certified && <img src={gold} alt="collega" />}
          </div>

          {!_.isEmpty(currentCandidateUser.profile.skills) && <>
            <span className={'skills'}>{strings.activityProfile.skills}</span>
            <div className={'skills__container'}>
              {currentCandidateUser.profile.skills.map((skill: any, index) => {
                return <span key={index} className={'label skill'}>{skill.name}</span>
              })}
            </div>
          </> }

          <div className={'panel__icon shadow'}>
            <img src={currentCandidateUser.profile.picture ? currentCandidateUser.profile.picture : profile} alt={'profile'} />
          </div>
        </div>

        {currentCandidateUser.is_certified && <div className={'certified__container'} >
          <img src={gold} alt="collega" />
          <div className={'text'}>
            <span className={'title'}>{strings.activityProfile.certified.title}</span>
            <span className={'subtitle'}>{strings.activityProfile.certified.subtitle} <b>{strings.activityProfile.certified.b}</b></span>
          </div>
        </div>
        }

        <div className={'info__container'}>
          <div className={'info'}>
            <span className={'title'}>{strings.activityProfile.info.personal.title}</span>

            <span className={'label'}>{strings.activityProfile.info.personal.email}</span>
            <span className={'content'} onClick={() => {
              AnalyticsService.event(AnalyticsEvents.profileContact, { field: 'email' })
              window.open('mailto:' + currentCandidateUser.email, "_self")
            }}>{currentCandidateUser.email}</span>

            <span className={'label'}>{strings.activityProfile.info.personal.phone}</span>
            <span className={'content'} onClick={() => {
              AnalyticsService.event(AnalyticsEvents.profileContact, { field: 'phone' })
              window.open('tel:' + currentCandidateUser.phone, "_self")
            }}>{currentCandidateUser.phone}</span>

            {/* <span className={'label'}>{strings.activityProfile.info.personal.fiscal_code}</span>
            <span className={'content'}>{currentCandidateUser.fiscal_code}</span> */}
          </div>
          <div className={'info'}>
            <span className={'title'}>{strings.activityProfile.info.professional.title}</span>

            <span className={'label'}>{strings.activityProfile.info.professional.city}</span>
            <span className={'content'}>{currentCandidateUser.profile.city!.name}</span>

            {/* <span className={'label'}>{strings.activityProfile.info.professional.vat}</span>
            <span className={'content'}>{currentCandidateUser.profile.vat}</span> */}

            {currentCandidateUser.profile.pec && <>
              <span className={'label'}>{strings.activityProfile.info.professional.pec}</span>
              <span className={'content'}>{currentCandidateUser.profile.pec}</span>
            </>}

            {currentCandidateUser.profile.register_number && <>
              <span className={'label'}>{strings.activityProfile.info.professional.register_number}</span>
              <span className={'content'}>{currentCandidateUser.profile.register_number}</span>
            </>}

            {currentCandidateUser.profile.coa_office && <>
              <span className={'label'}>{strings.activityProfile.info.professional.coa_office}</span>
              <span className={'content'}>{currentCandidateUser.profile.coa_office.name}</span>
            </>}
          </div>
          <div className={'info'}>
            <span className={'title'}>{strings.activityProfile.info.lawfirm.title}</span>

            <span className={'label'}>{strings.activityProfile.info.lawfirm.name}</span>
            <span className={'content'}>{currentCandidateUser.profile.lawfirm.name}</span>

            <span className={'label'}>{strings.activityProfile.info.lawfirm.city}</span>
            <span className={'content'}>{currentCandidateUser.profile.lawfirm.city!.name}</span>

            {currentCandidateUser.profile.lawfirm.address && <>
              <span className={'label'}>{strings.activityProfile.info.lawfirm.address}</span>
              <span className={'content'}>{currentCandidateUser.profile.lawfirm.address} </span>
            </>}

            {currentCandidateUser.profile.lawfirm.zip && <>
              <span className={'label'}>{strings.activityProfile.info.lawfirm.zip}</span>
              <span className={'content'}>{currentCandidateUser.profile.lawfirm.zip}</span>
            </>}

            {currentCandidateUser.profile.lawfirm.phone && <>
              <span className={'label'}>{strings.activityProfile.info.lawfirm.phone}</span>
              <span className={'content'} onClick={() => {
                AnalyticsService.event(AnalyticsEvents.profileContact, { field: 'phone_lawfirm' })
                window.open('tel:' + currentCandidateUser.profile.lawfirm.phone, "_self")
              }}>{currentCandidateUser.profile.lawfirm.phone}</span>
            </>}

            {currentCandidateUser.profile.lawfirm.fax && <>
              <span className={'label'}>{strings.activityProfile.info.lawfirm.fax}</span>
              <span className={'content'}>{currentCandidateUser.profile.lawfirm.fax}</span>
            </>}
          </div>
        </div>
      </div>

      <div className={'button__container'}>
        {(currentDemand.status === DemandsStatus.ASSIGNED || currentDemand.status === DemandsStatus.COMPLETED || currentDemand.status === DemandsStatus.PAID) && <button className={'button-primary'} style={{ marginBottom: 8 }} onClick={() => {
          AnalyticsService.event(AnalyticsEvents.profileContact, { field: 'phone_button' })
          window.open('tel:' + currentCandidateUser.phone, "_self")
        }}>
          {'Chiama'}
        </button>}

        {currentDemand.status === CandidatesStatus.PENDING && <button className={'button-primary'} onClick={() => {
          assignRequest(currentDemand, currentCandidate!)
          setIsLoading(true)
        }}>
          {isLoading ? <span className={'button-loading'} /> : strings.activityProfile.button}
        </button>}

      </div>

      {certified && <ConfirmModal content={certifiedType} />}
      {opened && currentCandidateUser && <ConfirmModal content={assignedType()} />}
    </div >
  );
};

export default ActivityRightProfile;
