import Office from "./Office"


export class Request {
    id?: number
    type?: string
    activity?: string
    money?: number
    notes?: string
    office?: Office
    subject?: string
    section?: string
    judge?: string
    datetime?: string
    skill?: {id: number, name: string}
    only_aiga: boolean = false
    only_certified: boolean = false
}