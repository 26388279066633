import React, { Fragment, useState, useEffect } from 'react';
import close from '../../image/icons/close.svg';
import paper from 'image/icons/activity/activity-right-paper.svg';
import hammer from 'image/icons/activity/activity-right-hammer.svg';
import domiciled from 'image/icons/activity/activity-right-domiciled.svg';
import profile from 'image/icons/profile-placeholder.svg'
import gold from 'image/icons/activity/collega-gold.svg';
import alert from 'image/icons/verify-alert.svg'
import { greyArrow } from 'image/icons';
import { useSelector } from 'react-redux';
import { IUserReducer } from 'store/reducers/UserReducer';
import { ActivitiesReducer, setCurrentDemand, setShouldPerformRequest, setCurrentCandidate, setActivityAssignedDemandsTab, setActivityDemandsTab } from 'store/reducers/ActivitiesReducer';
import { useDispatch } from 'react-redux';
import { setCurrentCandidateUser } from '../../store/reducers/ActivitiesReducer';
import ActivityStatuses from './ActivityStatuses';
import { Demand, Candidate } from 'store/types/Demand';
import { ActivitiesContext } from './ActivityRequest';
import _ from 'lodash';
import { CandidatesStatus, DemandsStatus, ManagementsStatus } from 'values/values';
import ActivitiesService from '../../services/ActivitiesService';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import { ActivityModal } from 'store/types/ActivityModal';
import modalGirl from 'image/icons/activity/modalGirl.svg'
import { useHistory } from 'react-router-dom';
import Utility from 'utils/utils';
import { IAppReducer, tabActivityOpen, tabActivityClose } from 'store/reducers/AppReducer';
import strings from 'values/strings';
import { User } from 'store/types/User';
import AnalyticsService, { AnalyticsScreens, AnalyticsEvents } from 'services/AnalyticsService';
import moment from 'moment';

const ActivityRightPanel: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const open = useSelector((state: IAppReducer) => state.app.isOpenTabActivity);

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const currentDemand = useSelector((state: ActivitiesReducer) => state.activitiesReducer.currentDemand);
  const currentCandidate = useSelector((state: ActivitiesReducer) => state.activitiesReducer.currentCandidate);
  let context = useSelector((state: ActivitiesReducer) => state.activitiesReducer.activitiesContext)

  let dispatch = useDispatch()
  let history = useHistory()

  let [cancelResponse, cancelError, cancelRequest] = ActivitiesService.useCancelDemandAPI()
  let [paidResponse, paidError, paidRequest] = ActivitiesService.usePaidDemandAPI()
  let [acceptResponse, acceptError, acceptRequest] = ActivitiesService.useAcceptCandidateAPI()
  let [rejectResponse, rejectError, rejectRequest] = ActivitiesService.useRejectCandidateAPI()
  let [completedResponse, completedError, completedRequest] = ActivitiesService.useCompletedDemandAPI()

  let [deleteDemandsResponse, , deleteDemandsRequest] = ActivitiesService.useDeleteDemandAPI()
  let [deleteCandidatesResponse, , deleteCandidatesRequest] = ActivitiesService.useDeleteCandidateAPI()
  let [deleteAssignedDemandsResponse, , deleteAssignedDemandsRequest] = ActivitiesService.useDeleteAssignedDemandAPI()

  const [candidate, setCandidate] = useState<boolean>(false)
  const [conclude, setConclude] = useState<boolean>(false)
  const [ended, setEnded] = useState<boolean>(false)
  const [incomplete, setInncomplete] = useState<boolean>(false)

  const candidateType: ActivityModal =
  {
    img: modalGirl,
    title: strings.activityPanel.modal.candidate.title,
    copy: strings.activityPanel.modal.candidate.copy,
    cta: strings.activityPanel.modal.candidate.cta,
    onConfirm: () => {
      setCandidate(false)
      setIsLoading(false)
      dispatch(setShouldPerformRequest(true))
    },
    onCancel: () => {
      setCandidate(false)
    },
  }

  const concludeType: ActivityModal =
  {
    img: modalGirl,
    title: strings.activityPanel.modal.conclude.title,
    copy: strings.activityPanel.modal.conclude.copy,
    cta: strings.activityPanel.modal.conclude.cta,
    ctaUndo: strings.activityPanel.modal.conclude.ctaUndo,
    onConfirm: () => { setConclude(false); completedRequest(currentDemand!); setIsLoading(true) },
    onCancel: () => setConclude(false),

  }

  const endedType: ActivityModal =
  {
    img: modalGirl,
    title: strings.activityPanel.modal.ended.title,
    copy: strings.activityPanel.modal.ended.copy,
    cta: strings.activityPanel.modal.ended.cta,
    ctaUndo: strings.activityPanel.modal.ended.ctaUndo,
    onConfirm: () => {
      setEnded(false)
      dispatch(setActivityAssignedDemandsTab(ManagementsStatus.COMPLETED))
      dispatch(setCurrentDemand(undefined))
      dispatch(setCurrentCandidateUser(undefined))
      dispatch(setCurrentCandidate(undefined))
    },
    onCancel: () => { setEnded(false); history.push("/dashboard") },
  }

  const incompleteType: ActivityModal =
  {
    img: alert,
    title: strings.activityPanel.modal.incomplete.title,
    copy: strings.activityPanel.modal.incomplete.copy,
    cta: strings.activityPanel.modal.incomplete.cta,
    ctaUndo: strings.activityPanel.modal.incomplete.ctaUndo,
    onConfirm: () => history.push("/profile-complete"),
    onCancel: () => setInncomplete(false),
  }

  useEffect(() => {
    if (open) AnalyticsService.screenName(AnalyticsScreens.activityDetail)
  }, [open])

  useEffect(() => {
    if (incompleteType) AnalyticsService.screenName(AnalyticsScreens.popupUncompletedProfile)
  }, [incompleteType])

  useEffect(() => {
    if (cancelResponse) dispatch(setShouldPerformRequest(true))
  }, [cancelResponse])

  useEffect(() => {
    if (paidResponse) {
      dispatch(setActivityDemandsTab(DemandsStatus.PAID))
      dispatch(setShouldPerformRequest(true))
    }
  }, [paidResponse])

  useEffect(() => {
    if (acceptResponse) {
      setIsLoading(false)
      setCandidate(true)
    }
  }, [acceptResponse])

  useEffect(() => {
    if (rejectResponse) {
      setIsLoading2(false)
      dispatch(setShouldPerformRequest(true))
    }
  }, [rejectResponse])

  useEffect(() => {
    if (completedResponse)
      setEnded(true)
    setIsLoading(false)
  }, [completedResponse])

  useEffect(() => {
    if (currentDemand) dispatch(tabActivityOpen())
    setIsLoading(false)
  }, [currentDemand])

  const onToggleArrowClick = () => {
    dispatch(tabActivityClose());
    setTimeout(() => { dispatch(setCurrentDemand(undefined)); }, 200);
  }

  let panelImg = () => {
    switch (currentDemand && currentDemand.type) {
      case 'CHANCELLERY': return paper
      case 'DOMICILIARY': return domiciled
      case 'SUBSTITUTE': return hammer
    }
  }

  let deleteRequest = (demand?: Demand, candidate?: Candidate) => {
    switch (context) {
      case ActivitiesContext.demands: return deleteDemandsRequest(demand!)
      case ActivitiesContext.candidates: return deleteCandidatesRequest(candidate!)
      case ActivitiesContext.managements: return deleteAssignedDemandsRequest(demand!)
    }
  }

  let deleteResponse = () => {
    switch (context) {
      case ActivitiesContext.demands: return deleteDemandsResponse
      case ActivitiesContext.candidates: return deleteCandidatesResponse
      case ActivitiesContext.managements: return deleteAssignedDemandsResponse
    }
  }

  useEffect(() => {
    if (deleteResponse()) dispatch(setShouldPerformRequest(true))
  }, [deleteResponse()])



  // RENDER
  if (!currentUser) { return null }
  if (!currentDemand) { return null }

  let renderCandidates = () => {
    switch (context) {
      case ActivitiesContext.demands:
        let title

        if (_.isEmpty(currentDemand.active_candidates)) {
          title = 'Nessun candidato'
        } else if (currentDemand.status === DemandsStatus.PENDING) {
          title = currentDemand.active_candidates.length === 1 ? strings.activityPanel.pending : strings.activityPanel.pendings
        } else {
          title = strings.activityPanel.assigned
        }

        return (
          <Fragment>
            <div className={'activity__colleagues__container'}>
              <span className={'title'}>{title}</span>

              {currentDemand.active_candidates!.map((candidate: Candidate, index: number, array: Array<Candidate>) => {
                return (
                  <div key={index} className="collegue__container " onClick={() => {
                    dispatch(setCurrentCandidateUser(candidate.user))
                    dispatch(setCurrentCandidate(candidate))
                  }}>
                    <div className={'icon'}> <img src={candidate.user!.profile.picture ? candidate.user!.profile.picture : profile} alt={'profile'} /></div>
                    <p>{candidate.user!.first_name + ' ' + candidate.user!.last_name}</p>
                    {candidate.user!.is_certified && <img src={gold} alt="collega" />}
                    <img src={greyArrow} alt="arrow" />
                  </div>
                )
              })}

            </div>
          </Fragment>
        )

      case ActivitiesContext.candidates:
        let user = currentDemand.user
        return (
          <Fragment>
            <div className={'activity__colleagues__container'}>
              <span className={'title'}>Attività creata da</span>

              <div className="collegue__container ">
                <div className={'icon'}> <img src={user!.profile.picture ? user!.profile.picture : profile} alt={'profile'} /></div>
                <p>{User.fullNameMasked(user!)}</p>
                {user!.is_certified! && <img src={gold} alt="collega" />}
              </div>

            </div>
          </Fragment>
        )

      case ActivitiesContext.managements:
        let _user = currentDemand.user
        let _candidate = new Candidate()
        _candidate.demand = currentDemand
        _candidate.user = _user

        return (
          <Fragment>
            <div className={'activity__colleagues__container'}>
              <span className={'title'}>Attività creata da</span>

              <div className="collegue__container " onClick={() => {
                dispatch(setCurrentCandidateUser(_user))
                dispatch(setCurrentCandidate(_user))
              }}>
                <div className={'icon'}> <img src={_user!.profile.picture ? _user!.profile.picture : profile} alt={'profile'} /></div>
                <p>{_user!.first_name + ' ' + _user!.last_name}</p>
                {_user!.is_certified! && <img src={gold} alt="collega" />}
                <img src={greyArrow} alt="arrow" />
              </div>

            </div>
          </Fragment>
        )


    }
  }

  let onAcceptRequest = () => {
    acceptRequest(currentCandidate!)
  }

  let onRejectRequest = () => {
    rejectRequest(currentCandidate!)
  }

  let renderCTAs = () => {
    switch (context) {
      case ActivitiesContext.demands:
        if (currentDemand.deleted) return null

        return (
          <Fragment>
            {currentDemand.status === DemandsStatus.PENDING && moment().isBefore(moment(currentDemand.created_at).add(2, 'minute')) && <div className={'button__container'}>
              <button className={'button-secondary'} onClick={() => {
                cancelRequest(currentDemand)
                setIsLoading(true)
              }} >
                {isLoading ? <span className={'button-loading'} /> : strings.activityPanel.button.cancel}</button>
            </div>}

            {currentDemand.status === DemandsStatus.COMPLETED && <div className={'button__container'}>
              <button className={'button-primary'} onClick={() => {
                AnalyticsService.event(AnalyticsEvents.activityPaid, { activity_id: currentDemand.id })
                paidRequest(currentDemand)
                setIsLoading(true)
              }} >
                {isLoading ? <span className={'button-loading'} /> : strings.activityPanel.button.paid}</button>
            </div>}
          </Fragment>
        )

      case ActivitiesContext.candidates:
        if (currentCandidate && currentCandidate.deleted) return null

        return (
          <Fragment>
            {currentCandidate && currentCandidate.status === CandidatesStatus.PENDING && <div className={'button__container'}>

              <button className={'button-primary'} onClick={() => {
                onAcceptRequest()
                setIsLoading(true)
              }} >
                {isLoading ? <span className={'button-loading'} /> : strings.activityPanel.button.accept}</button>

              <button style={{ marginTop: 16 }} className={'button-secondary'} onClick={() => {
                onRejectRequest()
                setIsLoading2(true)
              }} >
                {isLoading2 ? <span className={'button-loading'} /> : strings.activityPanel.button.reject}</button>

            </div >}

          </Fragment >
        )

      case ActivitiesContext.managements:
        if (currentDemand.deleted) return null

        return (
          <Fragment>
            {currentDemand.status === DemandsStatus.ASSIGNED && <div className={'button__container'}>
              <button className={'button-primary'} onClick={() => {
                AnalyticsService.event(AnalyticsEvents.activityCompleted, { activity_id: currentDemand.id })
                setConclude(true)
              }} >
                {isLoading ? <span className={'button-loading'} /> : strings.activityPanel.button.conclude}</button>
            </div >}
          </Fragment >
        )
    }
  }


  return (
    <div className={open ? 'activity__right__panel__container' : 'activity__right__panel__container closed'}>

      <div className={'close-right'}>
        <img src={close} style={{ cursor: 'pointer' }} alt={'close'} onClick={() => onToggleArrowClick()} />
      </div>

      {currentDemand && <div className={'activity__right__panel'}>
        <div className={'activity__type__container'}>
          <span className={'title'}>{Utility.localised(currentDemand.type)}</span>
          <span className={'date'}>{Demand.at(currentDemand)}</span>

          <span className={'subtitle'}>{strings.activityPanel.activity.office}</span>
          <span className={'event'}>{currentDemand.office!.name}</span>

          <span className={'subtitle'}>{strings.activityPanel.activity.activity}</span>
          <span className={'event'}>{currentDemand.activity}</span>

          {currentDemand.money && <Fragment>
            <span className={'subtitle'}>{strings.activityPanel.activity.money}</span>
            <span className={'event'}>{Utility.currency(currentDemand.money)}</span>
          </Fragment>}

          {!currentDemand.money && <Fragment>
            <span className={'subtitle'}>{strings.activityPanel.activity.money}</span>
            <span className={'event'}>{strings.activityPanel.activity.agreed}</span>
          </Fragment>}

          {/* {currentDemand.subject && <Fragment>
            <span className={'subtitle'}>{strings.activityPanel.activity.subject}</span>
            <span className={'event'}>{currentDemand.subject!.name}</span>
          </Fragment>} */}

          {currentDemand.skill && <Fragment>
            <span className={'subtitle'}>{strings.activityPanel.activity.skill}</span>
            <span className={'event'}>{currentDemand.skill!.name}</span>
          </Fragment>}

          {currentDemand.notes && <Fragment>
            <span className={'subtitle'}>{strings.activityPanel.activity.notes}</span>
            <span className={'event'}>{currentDemand.notes}</span>
          </Fragment>}

          <ActivityStatuses
            demand={currentDemand}
            candidate={currentCandidate}
          />

          <div className={'panel__icon shadow'}>
            <img src={panelImg()} alt={'panel'} />
          </div>
        </div>

        {renderCandidates()}

      </div>}

      {renderCTAs()}

      {candidate && <ConfirmModal content={candidateType} />}
      {conclude && <ConfirmModal content={concludeType} />}
      {ended && <ConfirmModal content={endedType} ended />}
      {incomplete && <ConfirmModal content={incompleteType} />}
    </div>
  );
};

export default ActivityRightPanel;
