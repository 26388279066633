import React, { FC, useState, useEffect } from 'react';
import { User } from 'store/types/User';
import ProfileSkills from './ProfileSkills';
import Modal from 'components/common/Modal';
import { Skill } from 'store/types/Profile';
import edit from '../../image/edit.svg';
import EmptySpecificSkillBanner from './EmptySpecificSkillBanner';
import UserService from 'services/UserServices';
import strings from 'values/strings';

type SpecificSkillsBannerProps = {
  currentUser: User;
}

const SpecificSkillsBanner: FC<SpecificSkillsBannerProps> = ({ currentUser }) => {
  const [specificSkillBanner, setSpecificSkillBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [skillsResponse, , skillsRequest] = UserService.useSearchSkillsAPI();

  useEffect(() => {
    skillsRequest('');
  }, []);

  useEffect(() => {
    if (skillsResponse) setIsLoading(false);
  }, [skillsResponse]);

  const specificSkills = currentUser.profile.skills.filter(skill => skill.is_main === false);

  return (
    <>
      {specificSkills.length < 1 ? (
        <EmptySpecificSkillBanner
          isForMainSkill={false}
          action={() => setSpecificSkillBanner(true)}
        />
      ) : (
        <div className={"filled-banner-container"}>
          <div className={"filled-banner-header"}>
            <span>Competenze specifiche</span>
            {/* <img
              src={edit}
              alt="edit-icon"
              onClick={() => setSpecificSkillBanner(true)}
            /> */}
          </div>
          <div className={"skill-container"}>
            {specificSkills.map((skill: Skill) => {
              return (
                <span key={skill.id} className={"skill-banner"}>
                  {skill.name}
                </span>
              );
            })}
          </div>
          <div className="add-skill-button">
            <button onClick={() => setSpecificSkillBanner(true)}>
              {strings.profile.basicCoWorker.addSkillButton}
            </button>
          </div>
        </div>
      )}

      {specificSkillBanner && !isLoading && (
        <Modal onClose={() => setSpecificSkillBanner(false)}>
          <ProfileSkills type="specific" allSkills={skillsResponse} maxCheck={5}/>
        </Modal>
      )}
    </>
  );
};

export default SpecificSkillsBanner;
