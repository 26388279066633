import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAgendaReducer,
  setSearchVisibility,
  toggleIsVisibleFilters,
  setSearchPage,
} from '../../store/reducers/AgendaReducer';
import CommitmentFilters from './CommitmentFilters';
import { IAppReducer, newCommitment } from '../../store/reducers/AppReducer';

import { add } from '../../image/icons';
import strings from '../../values/strings';
import EventsService from '../../services/EventService';
import { IUserReducer } from '../../store/reducers/UserReducer';
import _ from 'lodash';
import Calendar from '../common/Calendar';
import Search from 'components/search/Search';
import AnalyticsService, { AnalyticsEvents } from 'services/AnalyticsService';

const CommitmentHeader: React.FC = () => {

  const isVisibleFilters = useSelector((state: IAgendaReducer) => state.agenda.isVisibleFilters);
  const isVisibleSearch = useSelector((state: IAgendaReducer) => state.agenda.isVisibleSearch);
  const endReached = useSelector((state: IAgendaReducer) => state.agenda.endReached);
  const searchPage = useSelector((state: IAgendaReducer) => state.agenda.searchPage);

  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);

  const dispatch = useDispatch();

  const [searchResponse, , searchRequest] = EventsService.useSearchEventsAPI();

  useEffect(() => {
    AnalyticsService.event(AnalyticsEvents.agendaFilters, { visible: isVisibleFilters })
  }, [isVisibleFilters])

  useEffect(() => {
    setTimeout(async () => {
      if (endReached) {
        if (_.isEmpty(searchResponse)) return
        await dispatch(setSearchPage(searchPage + 1))
      }
    }, 0)
  }, [endReached])


  return (
    <div className={'commitment__header'} style={{ position: 'relative' }}>

      {/* CALENDAR */}
      <div className={'agenda__calendar'}>
        <Calendar />
      </div>

      {/* AGENDA */}
      <div className={'flex-row'}>
        <h1 style={{ position: 'relative' }}>{strings.agenda.header}
          {isLoading &&
            <span className={'button-loading'} style={{ width: 30, height: 30, left: 'calc(100% + 20px)' }} />}
        </h1>
        <div className={classNames('commitment__header__search', { visible: isVisibleSearch })}>
          {isVisibleSearch && <Search callback={searchRequest} />}
        </div>
        <div className={'commitment__header__cta'}>
          <button className={'button-search'} onClick={() => dispatch(setSearchVisibility(true))} />
          <button className={'button-filter'} onClick={() => dispatch(toggleIsVisibleFilters())} />
        </div>
      </div>
      <div className={'flex-row'}>
        <button className={'button-secondary icon'} onClick={() => dispatch(newCommitment())}>
          <span className={'icon'}><img src={add} alt="add" /></span>
          <span className={'txt'}>{strings.agenda.add.headerTitle}</span>
        </button>
      </div>
      {isVisibleFilters && <CommitmentFilters />}
    </div>
  );
};


export default CommitmentHeader;

