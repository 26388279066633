import React, { useState, useEffect } from 'react';
import Checkboxes from './checkbox/Checkboxes';
import { User } from 'store/types/User';
import { useSelector } from 'react-redux';
import { IUserReducer } from 'store/reducers/UserReducer';
import { Skill } from 'store/types/Profile';
import Storage from '../../store/Storage';
import UserService from 'services/UserServices';
import { useHistory } from 'react-router-dom';
import strings from 'values/strings';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import _ from 'lodash';

type ProfileSkillsProps = {
  maxCheck?: number;
  type?: 'basic' | 'specific';
  allSkills?: Array<Skill>;
  isButtonDisabled?: boolean;
}

const ProfileSkills: React.FC<ProfileSkillsProps> = ({ maxCheck, type, allSkills, isButtonDisabled }) => {

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
  const [skills, setSkills] = useState<Array<Skill>>([]);
  const [selectedSkills, setSelectedSkills] = useState<Array<Skill>>([]);
  const [updateResponse, , updateRequest] = UserService.useUpdateUserMeAPI();
  const history = useHistory();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.profileSkills);
  }, []);

  useEffect(() => {
    if (currentUser) {
      const filteredSkills = currentUser.profile.skills.filter(skill => type === 'basic' ? skill.is_main : !skill.is_main);
      const filteredOtherSkills = currentUser.profile.skills.filter(skill => type === 'basic' ? !skill.is_main : skill.is_main);
      setSkills(filteredSkills);
      setSelectedSkills(filteredOtherSkills);
      setIsSaveButtonDisabled(filteredSkills.length <= 0);
    }
  }, [currentUser, type]);

  useEffect(() => {
    if (updateResponse) history.replace('/profile');
  }, [updateResponse, history]);

  const onSave = async () => {
    if (!currentUser) {
      console.error('Current user is not defined');
      return;
    }

    const user: User = _.cloneDeep(currentUser);
    const updatedSkills = [
      ...selectedSkills,
      ...skills.map(skill => ({
        ...skill,
        is_main: type === 'basic'
      }))
    ];

    _.set(user.profile, 'skills', updatedSkills);
    updateRequest(user);
    await Storage.set(Storage.SHOW_SKILLS_BUTTONS, false);
  };

  const handleSkillsChange = (newSkills: Array<Skill>) => {
    setSkills(newSkills);
    setIsSaveButtonDisabled(newSkills.length >= 1);
  };

  return (
    <div className={'profile__edit__container'}>
      <div>
        <h2>{type==='basic' ? strings.profileSkills.basicTitle : strings.profileSkills.specificTitle}</h2>
        <h3 style={{fontFamily: 'Helvetica'}}>{type==='basic' ? '' : 'Puoi indicarne massimo 5'}</h3>
      </div>

      <div>
        <Checkboxes
          skills={skills}
          onChangeSelection={handleSkillsChange}
          max={maxCheck}
          type={type}
          selectedSkills={selectedSkills}
          allSkills={allSkills}
        />
      </div>
      <div style={{ alignSelf: 'end' }} onClick={onSave}>
        <button className={'skill-button'} disabled={!isSaveButtonDisabled && type === 'basic'}>
          {strings.save}
        </button>
      </div>
    </div>
  );
}

export default ProfileSkills;
