import React, {MouseEvent} from 'react';
// tslint:disable-next-line
import Autosugg, {
  BlurEvent,
  ChangeEvent,
  RenderSuggestionParams,
  SuggestionsFetchRequestedParams
} from 'react-autosuggest';
// tslint:disable-next-line
import match from 'autosuggest-highlight/match';
// tslint:disable-next-line
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';
import {add, basket} from '../../image/icons';

interface IAutoSuggest<TSuggestion> {
  onChange: (v: string) => void;
  onBlur?: (v?: TSuggestion) => void;
  readonly value: any;
  placeholder: string;
  suggestions: TSuggestion[];
  onFetch: (v: string) => void;
  onSelect?: (data: TSuggestion) => void;
  autoFocus?: boolean;
  readOnly?: boolean;
}


const AutoSuggest = <TSuggestion extends { getValue: () => string }>(props: IAutoSuggest<TSuggestion>) => {
  const {
    onChange, value,
    placeholder, suggestions,
    onFetch, onSelect, onBlur, autoFocus, readOnly
  } = props;

  const inputProps = {
    placeholder,
    value: value ? String(value) : '',
    onChange: (event: React.FormEvent<any>, params: ChangeEvent) => {
      onChange(params.newValue);
    },
    autoFocus,
    onBlur: (event: React.FocusEvent<any>, params?: BlurEvent<TSuggestion>) => {
      if (params && params.highlightedSuggestion) {
        onChange(params.highlightedSuggestion.getValue());
        if (onSelect) {
          onSelect(params.highlightedSuggestion);
        }
      }
      if (onBlur) {
        onBlur(params ? params.highlightedSuggestion : undefined);
      }
    },
    readOnly,
  };
  

  return (
    <Autosugg<TSuggestion>
      suggestions={suggestions}
      getSuggestionValue={(suggestion: TSuggestion) => suggestion ? suggestion.getValue() : ''}
      onSuggestionsFetchRequested={({value: v}: SuggestionsFetchRequestedParams) => onFetch(v)}
      onSuggestionsClearRequested={() => undefined}
      inputProps={inputProps}
      onSuggestionSelected={
        (e, {method, suggestion}) => {
          if (method === 'enter') {
            e.preventDefault();
          }
          if (onSelect) {
            onSelect(suggestion);
          }
        }
      }
      renderSuggestion={(sugg: TSuggestion, {query, isHighlighted}: RenderSuggestionParams) => {
        const text = sugg.getValue();
        const matches = match(text, query);
        const parts = parse(text, matches);
        return (
          <div className={classNames('suggestion-container', {hilighted: isHighlighted})}>
            {parts.map((part, index) => (
              <span key={index} className={classNames({highlighted: part.highlight})}>{part.text}</span>
            ))}
          </div>
        );
      }}
    />
  );
};

interface IInputSuggest extends IAutoSuggest<any> {
  label?: string;
  error?: string;
  cancelable?: boolean;
  onCancel?: (event?: MouseEvent<HTMLButtonElement>) => any;
}

const InputSuggest: React.FC<IInputSuggest> = ({label, error, cancelable, onCancel, ...rest}) => {
  return (
    <>
      {label && <label>{label}</label>}
      <div className={classNames({cancelable})}>
        <AutoSuggest
          {...rest}
        />
      </div>
      {cancelable &&
      <button className={'input-trash'} onClick={onCancel} type={'button'}
              tabIndex={-1}><img src={basket} alt="basket"/></button>}
      {error && <p className={'error'}>{error}</p>}
    </>
  );
};

interface ICancelableFieldProps extends IInputSuggest {
  showField: boolean;
  labelAdd: string;
  handleShow: (event?: MouseEvent<HTMLButtonElement>) => any;
}

const CancelableInputSuggestField: React.FC<ICancelableFieldProps> =
  ({
     showField,
     labelAdd,
     handleShow,
     ...rest
   }) => {
    return (
      <>
        {showField && (
          <InputSuggest
            {...rest}
            cancelable={true}
          />
        )}
        {!showField && (
          <button className={'button-secondary icon-right'}
                  type={'button'}
                  onClick={handleShow}>
            <span className={'txt'}>{labelAdd}</span>
            <span className={'icon'}><img src={add} alt="alert"/></span>
          </button>
        )}
      </>
    );
  };

export {CancelableInputSuggestField};

export default InputSuggest;
