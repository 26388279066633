import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUserReducer, updateTempUser } from '../../store/reducers/UserReducer';
import strings from '../../values/strings';
import InputIconValidate from '../../components/common/InputIconValidate';
import Input from '../../components/common/Input';
import { FormFields, RoleType } from '../../values/values';
import Utility from '../../utils/utils';
import classNames from 'classnames';
import { IStepReview } from './interface/SignupInterface';
import { IAppReducer } from '../../store/reducers/AppReducer';
import UserService from '../../services/UserServices';
import SelectOption from '../../store/types/SelectionOption';
import InputAutosuggest from '../../components/common/AutoSuggest';
import Select from '../../components/common/Select';
import AnalyticsService, { AnalyticsScreens, AnalyticsEvents } from 'services/AnalyticsService';

const options = [
  new SelectOption<RoleType>(RoleType.LAWYER, 'Avvocato'),
  new SelectOption<RoleType>(RoleType.ESTABLISHED, 'Avvocato stabilito'),
  new SelectOption<RoleType>(RoleType.PRACTICING, 'Praticante avvocato'),
  new SelectOption<RoleType>(RoleType.QUALIFIED, 'Praticante avvocato abilitato')
];

const StepReview: React.FC<IStepReview> = (props) => {
  const { first_name, last_name, email, password, unHidePassword, phone, city, serverError, next } = props;

  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
  const isLoading = useSelector((state: IAppReducer) => state.app.isLoading);
  const dispatch = useDispatch();

  const [serverValidation, setServerValidation] = useState(false);
  const [inReview, setInReview] = useState(false);
  const [lawfirmCity, setLawfirmCity] = useState<any>({
    query: '',
    object: undefined
  })

  const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();


  useEffect(() => {
    sendAnalyticsScreenName()
  }, [])

  useEffect(() => {
    sendAnalyticsScreenName()
  }, [inReview])

  useEffect(() => {
    if (lawfirmCity.object !== undefined) return
    if (tempUser && tempUser.profile.lawfirm.city) setLawfirmCity({ query: tempUser.profile.lawfirm.city.name, object: tempUser.profile.lawfirm.city })
  }, [tempUser])

  useEffect(() => {
    updateLegalOffice(FormFields.city, lawfirmCity.object)

  }, [lawfirmCity])

  useEffect(() => {
    if (Object.keys(serverError.error).length > 0) {
      setServerValidation(true);
      setInReview(true)
    }

  }, [serverError.error]);


  let updateProfile = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile,
      [FormFields.skills]: Array<any>(),
      [field]: value
    }

    await dispatch(updateTempUser(FormFields.profile, data));
  }

  let updateLegalOffice = async (field: string, value: any) => {
    let data = {
      ...tempUser!.profile.lawfirm,
      [field]: value
    }

    await updateProfile(FormFields.lawfirm, data)
  }


  const unHide = () => {
    unHidePassword.setValidity(!unHidePassword.valid);
  }

  const preventSubmit = (callback?: () => void) => {
    return (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (callback) {
        callback();
      }
    };
  };

  let typeValue = () => {
    if (!tempUser!.profile.type) return undefined
    return options.find((option) => option.value === tempUser!.profile.type)
  }

  if (!tempUser) {
    return null;
  }

  let sendAnalyticsScreenName = () => {
    if (inReview) AnalyticsService.event(AnalyticsEvents.registerEdit, undefined)
    AnalyticsService.screenName(inReview ? AnalyticsScreens.registerSummaryEdit : AnalyticsScreens.registerSummary)
  }


  return (
    <form onSubmit={preventSubmit(next)} className={'review'}>
      <div className={'review__form'}>
        <div className={'title_container'}>
        <h1 className={'title'}>{strings.signUp.summary.headerTitle}</h1>
        <a className={'help'} href={'mailto:' + strings.navMenu.support_email.address + '?subject=' + strings.signUp.help_subject} target={'_blank'}>{strings.signUp.help}</a>
      </div>
        <p>{strings.signUp.summary.headerDescription}</p>

        <div className={'review__form__row'}>
          {/*Role*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>

              <div className={'select-container'}>
                <Select<SelectOption>
                  label={strings.fieldLabelFirstRoleSummary}
                  placeholder={strings.profileEdit.step.qualification.placeholder}
                  value={typeValue()}
                  defaultValue={typeValue()}
                  options={options}
                  onChange={(option: SelectOption) => updateProfile(FormFields.type, option.value)}
                  readonly={isLoading || !inReview}
                  disabled={!inReview} />
              </div>

            </div>
          </div>
        </div>
        <div className={'review__form__row'}>
          {/*Name*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>{strings.fieldLabelFirstNameSummary}</label>
              {serverValidation && <InputIconValidate isValid={first_name.valid} />}
            </div>
            <Input
              type="text"
              placeholder={strings.fieldPlaceholderFirstName}
              value={tempUser!.first_name}
              readOnly={isLoading || !inReview}
              disabled={!inReview}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updateTempUser(FormFields.first_name, event.target.value));
                    if (event.target.value.length > 0) {
                      first_name.setValidity(true);
                      const { first_name: _, ...rest } = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    first_name.setValidity(tempUser!.first_name !== undefined);
                  }
                }
              }
              error={!first_name.valid ? (serverError.error.first_name ? serverError.error.first_name : strings.fieldPlaceholderFirstName) : undefined}
            />
          </div>

          {/*Surname*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>{strings.fieldLabelLastNameSummary}</label>
              {serverValidation && <InputIconValidate isValid={last_name.valid} />}
            </div>
            <Input
              type="text"
              readOnly={isLoading || !inReview}
              disabled={!inReview}
              placeholder={strings.fieldPlaceholderLastName}
              value={tempUser!.last_name}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updateTempUser(FormFields.last_name, event.target.value));
                    if (tempUser!.last_name) {
                      last_name.setValidity(true);
                      const { last_name: _, ...rest } = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    last_name.setValidity(tempUser!.last_name !== undefined);
                  }
                }
              }
              error={!last_name.valid ? (serverError.error.last_name ? serverError.error.last_name : strings.fieldPlaceholderLastName) : undefined}
            />
          </div>
        </div>


        <div className={'review__form__row'}>
          {/*Email*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>E-mail</label>
              {serverValidation && <InputIconValidate isValid={email.valid} />}
            </div>
            <Input
              type="text"
              readOnly={isLoading || !inReview}
              disabled={!inReview}
              placeholder={strings.fieldPlaceholderEmail}
              value={tempUser!.email}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updateTempUser(FormFields.email, event.target.value));
                    if (Utility.validateEmail(event.target.value)) {
                      email.setValidity(true);
                      const { email: _, ...rest } = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    email.setValidity(Utility.validateEmail(event.target.value));
                  }
                }
              }
              error={!email.valid ? (serverError.error.email ? serverError.error.email : strings.errors.invalidEmail) : undefined}
            />
          </div>

          {/*Password*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>Password</label>
              {serverValidation && <InputIconValidate isValid={password.valid} />}
            </div>
            <div className={'password-container'}>
              <span className={classNames('unhide', { hide: unHidePassword.valid })} onClick={unHide} />
              <Input
                type={!unHidePassword.valid ? 'password' : 'text'}
                readOnly={isLoading || !inReview}
                disabled={!inReview}
                placeholder={strings.fieldPlaceholderPassword}
                value={tempUser!.password1}
                onChange={
                  (event?: React.ChangeEvent<HTMLInputElement>) => {
                    if (event) {
                      dispatch(updateTempUser(FormFields.password, event.target.value));
                      if (Utility.validatePassword(event.target.value)) {
                        password.setValidity(true);
                        const { password1: _, ...rest } = serverError.error;
                        serverError.setError(rest);
                      }
                    }
                  }
                }
                onBlur={
                  (event?: React.FocusEvent<HTMLInputElement>) => {
                    if (event) {
                      password.setValidity(Utility.validatePassword(event.target.value));
                    }
                  }
                }
                error={!password.valid ? (serverError.error.password1 ? serverError.error.password1 : strings.errors.invalidPassword) : undefined}
              />
            </div>
          </div>
        </div>


        <div className={'review__form__row'}>
          {/*City*/}
          <div className={'review__form__col'} onClick={(e) => !inReview ? e.preventDefault() : null}>
            <div className={'flex-row'}>
              <label>{strings.signUp.fifth.cityLabel}</label>
              {serverValidation && <InputIconValidate isValid={city.valid} />}
            </div>

            <div style={{ width: '100%' }}>
              <InputAutosuggest
                readOnly={isLoading || !inReview}
                placeholder={strings.signUp.fifth.cityPlaceholder}
                suggestions={citiesResponse || []}
                onChange={(v) => { console.log(v); setLawfirmCity({ query: v, object: undefined }) }}
                onSelect={(data) => setLawfirmCity({ query: data.name, object: data.value })}
                value={lawfirmCity.query}
                error={!city.valid ? ((serverError.error.profile && serverError.error.profile.city) ? serverError.error.profile.city : strings.errors.invalidField) : undefined}
                onFetch={citiesRequest} />
            </div>

          </div>

        </div>
        <div className={'review__form__row'}>
          {/*Phone*/}
          <div className={'review__form__col'}>
            <div className={'flex-row'}>
              <label>Numero di telefono</label>
              {serverValidation && <InputIconValidate isValid={phone.valid} />}
            </div>
            <Input
              readOnly={isLoading || !inReview}
              disabled={!inReview}
              placeholder={strings.fieldPlaceholderPhone}
              value={tempUser!.phone}
              onChange={
                (event?: React.ChangeEvent<HTMLInputElement>) => {
                  if (event) {
                    dispatch(updateTempUser(FormFields.phone, event.target.value));
                    if (Utility.validatePhone(event.target.value)) {
                      phone.setValidity(true);
                      const { phone: _, ...rest } = serverError.error;
                      serverError.setError(rest);
                    }
                  }
                }
              }
              onBlur={
                (event?: React.FocusEvent<HTMLInputElement>) => {
                  if (event) {
                    phone.setValidity(Utility.validatePhone(event.target.value));
                  }
                }
              }
              error={!phone.valid ? (serverError.error.phone ? serverError.error.phone : strings.errors.invalidPhone) : undefined}
            />
          </div>
        </div>

      </div>

      <div className={!inReview ? 'review__cta' : 'review__cta save'}>
        {!inReview && <button
          className={'button-secondary'}
          type="button"
          onClick={() => { setInReview(true) }}>{strings.signUp.stepReview.edit}</button>}

        {inReview && <button
          className={classNames('button-primary')}
          onClick={() => setInReview(false)}>
          <span className={classNames({ unvisible: isLoading })}>{strings.signUp.stepReview.save}</span>
        </button>}

        {!inReview && <button
          className={classNames('button-primary', { disabled: !(tempUser!.checkSignUpValidity()) || Object.keys(serverError.error).length > 0 })}>
          <span className={classNames({ unvisible: isLoading })}>{strings.signUp.stepReview.create}</span>
          {isLoading && <span className={'button-loading'} />}
        </button>}

      </div>
    </form>
  );
};

export default StepReview;
