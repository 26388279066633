import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import logo from 'image/logo-collega.svg';
import down from 'image/icons/profile-arrow.svg';
import profile from 'image/icons/profile-placeholder.svg'
import { Link, Route } from 'react-router-dom';
import Notifications from 'components/notifications/Notifications';
import { User } from 'store/types/User';
import Utility from 'utils/utils';
import strings from 'values/strings';
import { setCurrentDay } from 'store/reducers/AgendaReducer';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormat } from 'values/values';
import { IUserReducer } from 'store/reducers/UserReducer';

interface Props {

}

const Header: React.FC<Props> = () => {

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);

  const dispatch = useDispatch();

  const openContextMenu = () => {
    setIsOpenContextMenu(true);
  };

  const closeContextMenu = () => {
    setIsOpenContextMenu(false);
  };

  const setToday = () => {
    dispatch(setCurrentDay());
    setTimeout(() => {
      dispatch(setCurrentDay(moment(new Date()).format(DateFormat.server)));
    }, 1);
  };

  // RENDER

  // if (!currentUser) { return null }

  return (
    <div className={currentUser ? 'agenda__header' : 'agenda__header courses'}>

      <div className={'logo'}>
        <Link to={'/dashboard'}><img className={'logo'} src={logo} alt="Logo collega" /></Link>
      </div>

      {currentUser && <div className={'cta-container'}>
        <Route path="/agenda">
          <button className={'button-primary'} style={{ marginRight: '48px' }} onClick={setToday}>{strings.headerProfile.today}</button>
        </Route>

        <Notifications />

        {/*MENU PROFILO*/}
        <div className={'cta-profile'} onClick={openContextMenu}>
          <div className={'img__container '}>
            <img src={currentUser.profile.picture ? currentUser.profile.picture : profile} alt={User.fullName(currentUser)} />
            {currentUser.is_certified && <div className={'img_badge'}></div>}
          </div>
          <div className={'text'}>
            <h2>{User.fullName(currentUser)}</h2>
            <p>{Utility.localised(currentUser.profile.type)}</p>
          </div>
          <img src={down} alt={'arrow'} />
        </div>

        {isOpenContextMenu && (
          <OutsideClickHandler onOutsideClick={() => closeContextMenu()}>
            <div className={window.location.pathname !== '/agenda' ? 'menu-profile menu-profile-secondary' : 'menu-profile'}>
              <ul>
                <Route path="*">
                  <li>
                    <Link to="/profile" onClick={() => {
                      closeContextMenu();
                    }}>{strings.headerProfile.cta}</Link>
                  </li>
                </Route>
                <li>
                  <Link to="/logout">{strings.headerProfile.logout}</Link>
                </li>
              </ul>
            </div>
          </OutsideClickHandler>
        )}

      </div>}

    </div>
  );
};

export default Header;
