export let AppName = 'Collega';

export let AdM_Home = 'div-gpt-ad-1671563468153-0'
export let AdM_Notifications = 'div-gpt-ad-1671564637798-0'


export enum FormFields {
  type = 'type',
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  password = 'password',
  password1 = 'password1',
  password2 = 'password2',
  birth_date = 'birth_date',
  fiscal_code = 'fiscal_code',
  phone = 'phone',
  terms = 'terms',
  privacy = 'privacy',
  gdpr = 'gdpr',

  email_enabled = 'email_enabled',
  notifications_enabled = 'notifications_enabled',

  profile = 'profile',
  picture = 'picture',
  city = 'city',
  city2 = 'city2',

  skills = 'skills',
  
  // Profile professional info
  vat = 'vat',
  pec = 'pec',
  register_number = 'register_number',
  coa_office = 'coa_office',

  // Profile law firm
  lawfirm = 'lawfirm',
  name = 'name',
  address = 'address',
  fax = 'fax',
  zip = 'zip',

  //
  resourcetype = 'resourcetype',
  part1 = 'part1',
  part2 = 'part2',
  activity = 'activity',
  office = 'office',
  notice = 'notice_days',
  judge = 'judge',
  status = 'status',
  date = 'date',
  start = 'start',
  end = 'end',
  nrg = 'nrg',
  notes = 'notes',
  expense1 = 'expense1',
  expense2 = 'expense2',
  expense3 = 'expense3',
  allDay = 'all_day',

  // Request
  section = 'section',
  datetime = 'datetime',
  money = 'money',
  subject = 'subject',
  skill = "skill",
  only_aiga = 'only_aiga',
  only_certified = 'only_certified',
}

export enum RoleType {
  LAWYER = 'LAWYER',
  ESTABLISHED = 'ESTABLISHED',
  PRACTICING = 'PRACTICING',
  QUALIFIED = 'QUALIFIED'
}

export enum MonthType {
  JAN = 'JAN',
  FEB = 'FEB',
  MAR = 'MAR',
  APR = 'APR',
  MAY = 'MAY',
  JUN = 'JUN',
  JUL = 'JUL',
  AUG = 'AUG',
  SEPT = 'SEPT',
  OCT = 'OCT ',
  NOV = 'NOV',
  DEC = 'DEC ',
}

export enum ResourceType {
  Hearing = 'Hearing',
  Appointment = 'Appointment',
  Fulfillment = 'Fulfillment',
  Notice = 'Notice',
  News = 'News'
}

export enum EventStatus {
  TODO = 'TODO',
  DONE = 'DONE',
  POSTPONED = 'POSTPONED'
}

export enum NoticeType {
  _1 = 'Il giorno prima',
  _2 = 'Due giorni prima',
  _7 = 'Una settimana prima',
  _14 = 'Due settimane prima'
}

export enum PartsType {
  parts1 = 'parts1',
  parts2 = 'parts2'
}


export enum ExternalLinks {
  Support = 'mailto:info@collegaonline.it?subject=Feedback AIGA: Agenda Legale&body=Caro team dell\' applicazione AIGA: Agenda Legale, ho utilizzato la vostra applicazione, e desidererei condividere il mio feedback: ',
  Terms = 'https://collegaonline.it/informazioni-legali/', 
  Privacy = 'https://www.iubenda.com/privacy-policy/7874198',
  iskilled = 'https://www.iskilled.net',
  fp4 = '/web/courses/home/',
  CollegaWebsite = 'https://collegaonline.it',
  PaymentStart = '/web/payments/collect/',
  CollegaShop = 'http://shop.collegaonline.it'
}


export enum DateFormat {
  profile = 'DD/MM/YYYY',
  server = 'YYYY-MM-DD',
  fullMonth = 'MMMM',
  month = 'MM',
  dayShort = 'ddd',
  dayWithNumber = 'dddd D',
  dayWithNumberAndMounth = 'dddd D MMMM',
  dayWithNumberAndMounthAndYear = 'dddd D MMMM YYYY',
  dayNumber = 'D',
  timeMicro = 'HH:mm:ss.SSSSSS',
  timeSecond = 'HH:mm:ss',
  time = 'HH:mm',
  full = 'DD MMMM YYYY',
  fullCalendar = 'YYYY-MM-DD HH:mm'
}


// ACTIVITIES

export enum DemandsStatus {
  ALL = 'ALL',
  PENDING = 'PENDING',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  PAID = 'PAID',
  ANNULLED = 'ANNULLED',
  EXPIRED = 'EXPIRED'
}

export enum CandidatesStatus {
  ALL = 'ALL',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DEAD = 'DEAD'
}

export enum ManagementsStatus {
  ALL = 'ALL',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  PAID = 'PAID'
}