import Office from './Office';
import City from './City';

export class Profile {
  type?: string
  picture?: string
  is_complete: boolean = false
  city?: City;
  collega_section?: Office;
  skills: Array<Skill> = []
  aiga_section?: Office
  pec?: string
  vat?: string
  coa_office?: Office
  register_number?: string
  lawfirm: LegalOffice = new LegalOffice()
  credits?: number

  is_cassationist?: boolean
  is_civil_and_commercial_mediator?: boolean
  birth_month?: string

  constructor(parameters?: Partial<Profile>) {
    if (parameters) {

      const { city, collega_section, ...object } = parameters;

      Object.assign(this, object);
      if (city) {
        this.city = new City(city);
      }
      if (collega_section) {
        this.collega_section = new Office(collega_section);
      }
    }

  }
}


export class Skill {
  id?: number
  name?: string
  is_main?: boolean 
}

export class Subject {
  id?: number
  name?: string
}


export class LegalOffice {
  id?: number
  city?: City
  city2?: City
  name?: string
  phone?: string
  fax?: string
  address?: string
  zip?: string

  static cityCompiled = (legalOffice: LegalOffice) => {
    return legalOffice.city && legalOffice.name
  }

  static allCompiled = (legalOffice: LegalOffice) => {
    return legalOffice.city && legalOffice.name && legalOffice.address }
}

