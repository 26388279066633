import { Profile } from './Profile';
import Utility from '../../utils/utils';

export class User {

  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  birth_date?: string;
  phone?: string;
  fiscal_code?: string;
  password1?: string;
  password2?: string;
  under46: boolean = false;
  terms: boolean = false;
  privacy: boolean = false;
  gdpr: boolean = false;
  trial_expires_at?: string;

  profile: Profile = new Profile()

  created_at?: string

  is_certified: boolean = false
  is_aiga: boolean = false

  email_enabled?: boolean
  notifications_enabled?: boolean

  last_payment?: LastPayment

  temp_demand?: string
  is_new: boolean = true
  missing_popups: Array<{id: number, name: string}> = []

  // constructor(parameters: Partial<User>) {
  //   const { first_name, last_name, email, profile, ...obj } = parameters;

  //   Object.assign(this, obj);

  //   this.first_name = first_name || '';
  //   this.last_name = last_name || '';
  //   this.email = email || '';

  //   if (profile) {
  //     this.profile = new Profile(profile);
  //   }

  //   if (this.birth_date && !Utility.isUnder46(this.birth_date)) {
  //     this.under46 = false;
  //   }
  // }


  static fullNameMasked = (user: User): string => {
    return user.first_name + ' ' + user.last_name!.charAt(0) + '.'
  }

  static fullName = (user: User): string => {
    return user.first_name + ' ' + user.last_name
  }

  checkDateField() {
    return // !!this.birth_year && !!this.birth_day && this.birth_month !== '-1';
  }

  checkDateValidity() {
    return true
  }

  validateType() {
    return this.profile.type !== undefined
  }

  validateName() {
    return this.first_name !== undefined && this.last_name !== undefined;
  }

  validateCredentials() {
    return Utility.validateEmail(this.email) && Utility.validatePassword(this.password1)
  }

  validateFlag() {
    return this.privacy && this.terms;
  }

  checkSignUpValidity() {
    return this.validateType() && this.validateName() && this.validateFlag() &&
      Utility.validateEmail(this.email) && Utility.validatePassword(this.password1) &&
      Utility.validatePhone(this.phone);
  }
}

export class LastPayment {
  id?: number
  expire_at?: string
  subscription_id?: string
  payment_type?: PaymentType
  renewal?: boolean
}


export class PaymentType {
  id?: number
  description?: string
  price?: string
  months?: number
  status?: boolean
  plan_id?: string
}