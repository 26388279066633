import React from 'react';
import { Skill } from 'store/types/Profile';
import classNames from 'classnames';
import _ from 'lodash';

interface Props {
  skills: Array<Skill>;
  max?: number;
  onChangeSelection?: (skills: Array<Skill>) => void;
  type?: 'basic' | 'specific';
  selectedSkills?: Array<Skill>;
  allSkills?: Array<Skill>;
}

const Checkboxes: React.FC<Props> = ({ skills, max, onChangeSelection, type, selectedSkills, allSkills }) => {

  const handleSelection = (skill: Skill, selected: boolean) => {
    if (type === 'basic') {
      const updatedSkills = !selected ? [skill] : [];
      onChangeSelection && onChangeSelection(updatedSkills);
    } else {
      if (max && skills.length === max && !selected) return;
      const updatedSkills = !selected ? [...skills, skill] : skills.filter(_skill => skill.id !== _skill.id);
      onChangeSelection && onChangeSelection(updatedSkills);
    }
  };

  const filteredSkills = allSkills 
    ? allSkills.filter(skill => skill.name !== 'Generica' && !_.find(selectedSkills, { id: skill.id }))
    : [];

  return (
    <div>
      <div className={"checkbox-container"}>
        {filteredSkills.map((skill: Skill) => {
          const selected = _.find(skills, { id: skill.id }) !== undefined;
          return (
            <div key={skill.id} className={classNames("checkbox-content")}>
              <p>{skill.name}</p>
              <div
                className={classNames("check", { checked: selected })}
                onClick={() => handleSelection(skill, selected)}
              >
                <input type={"checkbox"} checked={selected} readOnly />
                <span />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Checkboxes;
