import React, { FC, useState, useEffect } from 'react';
import UserService from 'services/UserServices';
import { User } from 'store/types/User';

type QualificationOptionsProps = {
  currentUser: User;
}

const QualificationOptions: FC<QualificationOptionsProps> = ({ currentUser }) => {
  const [isCassazionista, setIsCassazionista] = useState<boolean | undefined>(false);
  const [isMediatore, setIsMediatore] = useState<boolean | undefined>(false);

  useEffect(() => {
    setIsCassazionista(currentUser.profile.is_cassationist);
    setIsMediatore(currentUser.profile.is_civil_and_commercial_mediator);
  }, [currentUser]);

  const [updateResponse, , updateRequest] = UserService.useUpdateUserMeAPI();

  const handleCassazionistaChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = event.target.checked;
    setIsCassazionista(isSelected);

    const updatedUser = new User();
    Object.assign(updatedUser, currentUser, {
      profile: {
        ...currentUser.profile,
        is_cassationist: isSelected,
      },
    });

    try {
      updateRequest(updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleMediatoreChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = event.target.checked;
    setIsMediatore(isSelected);

    const updatedUser = new User();
    Object.assign(updatedUser, currentUser, {
      profile: {
        ...currentUser.profile,
        is_civil_and_commercial_mediator: isSelected,
      },
    });

    try {
      updateRequest(updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <div className="wrapper">
      <label className={`checkbox-content ${isCassazionista ? 'checked' : ''}`}>
        <input 
          type="checkbox" 
          name="qualification" 
          value="Cassazionista" 
          className="radio" 
          checked={isCassazionista} 
          onChange={handleCassazionistaChange} 
        />
        <span className={`check ${isCassazionista ? 'checked' : ''}`}>
          <span className="checkmark"></span>
        </span>
        Cassazionista
      </label>
      <label className={`checkbox-content ${isMediatore ? 'checked' : ''}`}>
        <input 
          type="checkbox" 
          name="qualification" 
          value="Mediatore Civile e Commerciale" 
          className="radio" 
          checked={isMediatore} 
          onChange={handleMediatoreChange} 
        />
        <span className={`check ${isMediatore ? 'checked' : ''}`}>
          <span className="checkmark"></span>
        </span>
        Mediatore Civile e Commerciale
      </label>
    </div>
  );
};

export default QualificationOptions;
