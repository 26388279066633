import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Network, { NetworkMethod } from '../network/Network';
import { setIsLoading } from '../store/reducers/AppReducer';
import _ from 'lodash';
import { Request } from 'store/types/Request';
import { ManagementsStatus, CandidatesStatus, DemandsStatus } from 'values/values';
import { Demand, Candidate } from 'store/types/Demand';

class ActivitiesService {

  static useCreateRequestAPI = (): [any, any, (request: Request) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();

    let request = async (request: Request) => {
        dispatch(setIsLoading(true));

        let method = NetworkMethod.post;
        let endpoint = 'demands/';
        let data = JSON.stringify(request);

        let response = await Network.fetchAPI(method, endpoint, true, data, null);
        dispatch(setIsLoading(false));

        setError(response.error);

        if (response.data) {
            setResponse(response.data);
        }
    };

    return [response, error, request];
};


  //GUEST DEMANDS
  static useGuestCreateRequestAPI = (): [any, any, (request: Request) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (request: Request) => {
      dispatch(setIsLoading(true))

      let modifiedRequest = {
        ...request,
        skill: request.skill && request.skill.id,
      };

      let method = NetworkMethod.post
      let endpoint = 'temp-demands/'
      let data = JSON.stringify(modifiedRequest)

      let response = await Network.fetchAPI(method, endpoint, true, data, null)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useGetEstimationsAPI = (): [any, any, (skillId: number, officeId: number) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch();
  
    let request = async (skillId: number, officeId: number) => {
      dispatch(setIsLoading(true));
  
      let method = NetworkMethod.get;
      let endpoint = `demands/estimate/`;

      let queryParams = new URLSearchParams({
        skill_id: skillId.toString(),
        office_id: officeId.toString(),
      }).toString();

      let url = `${endpoint}?${queryParams}`;

      let response = await Network.fetchAPI(method, url, true, null, null);
      dispatch(setIsLoading(false));
  
      setError(response.error);
  
      if (response.data) {
        setResponse(response.data);
      }
    };
  
    return [response, error, request];
  };
  

  // LIST

  static useDemandsRequestsAPI = (): [any, any, (status: DemandsStatus | string | undefined, page: number, query?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (status: DemandsStatus | string | undefined, page: number, query?: string) => {
      dispatch(setIsLoading(page === 1 && query === undefined))

      let method = NetworkMethod.get
      let endpoint = 'demands/'
      let params = {
        search: query,
        page: page
      }
      if (status !== DemandsStatus.ALL) _.set(params, 'status', status)

      let response = await Network.fetchAPI(method, endpoint, true, null, params)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useCandidatesRequestsAPI = (): [any, any, (status: CandidatesStatus | string | undefined, page: number, query?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (status: CandidatesStatus | string | undefined, page: number, query?: string) => {
      dispatch(setIsLoading(page === 1 && query === undefined))

      let method = NetworkMethod.get
      let endpoint = 'invitations/received/'
      let params = {
        search: query,
        page: page
      }
      if (status !== CandidatesStatus.ALL) _.set(params, 'status', status)

      let response = await Network.fetchAPI(method, endpoint, true, null, params)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }

  static useAssignedDemandsRequestsAPI = (): [any, any, (status: ManagementsStatus | string | undefined, page: number, query?: string) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (status: ManagementsStatus | string | undefined, page: number, query?: string) => {
      dispatch(setIsLoading(page === 1 && query === undefined))

      let method = NetworkMethod.get
      let endpoint = 'received-demands/'
      let params = {
        search: query,
        page: page
      }
      if (status !== ManagementsStatus.ALL) _.set(params, 'status', status)

      let response = await Network.fetchAPI(method, endpoint, true, null, params)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }

    }

    return [response, error, request]
  }


  // DELETE

  static useDeleteDemandAPI = (): [any, any, (demand: Demand) => void] => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (demand: Demand) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.delete
      let endpoint = 'demands/' + demand.id + '/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)
      setResponse(response.data !== undefined)
    }

    return [response, error, request]
  }

  static useDeleteCandidateAPI = (): [any, any, (candidate: Candidate) => void] => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (candidate: Candidate) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.delete
      let endpoint = 'invitations/received/' + candidate.id + '/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)
      setResponse(response.data !== undefined)
    }

    return [response, error, request]
  }

  static useDeleteAssignedDemandAPI = (): [any, any, (demand: Demand) => void] => {
    const [response, setResponse] = useState<any>();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (demand: Demand) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.delete
      let endpoint = 'received-demands/' + demand.id + '/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)
      setResponse(response.data !== undefined)
    }

    return [response, error, request]
  }




  // CANCEL

  static useCancelDemandAPI = (): [any, any, (demand: Demand) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (demand: Demand) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.get
      let endpoint = 'demands/' + demand.id + '/annulled/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  // ACCEPT

  static useAcceptCandidateAPI = (): [any, any, (candidate: Candidate) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (candidate: Candidate) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.get
      let endpoint = 'invitations/received/' + candidate.id + '/accept/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  // REJECT

  static useRejectCandidateAPI = (): [any, any, (candidate: Candidate) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (candidate: Candidate) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.get
      let endpoint = 'invitations/received/' + candidate.id + '/reject/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  // ASSIGN

  static useAssignDemandAPI = (): [any, any, (demand: Demand, candidate: Candidate) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (demand: Demand, candidate: Candidate) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.get
      let endpoint = 'demands/' + demand.id + '/invitations/' + candidate.id + '/assign/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }


  // COMPLETED

  static useCompletedDemandAPI = (): [any, any, (demand: Demand) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (demand: Demand) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.get
      let endpoint = 'received-demands/' + demand.id + '/completed/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }



  // PAID

  static usePaidDemandAPI = (): [any, any, (demand: Demand) => void] => {
    const [response, setResponse] = useState();
    const [error, setError] = useState();
    const dispatch = useDispatch()

    let request = async (demand: Demand) => {
      dispatch(setIsLoading(true))

      let method = NetworkMethod.get
      let endpoint = 'demands/' + demand.id + '/paid/'

      let response = await Network.fetchAPI(method, endpoint, true, null, null)
      dispatch(setIsLoading(false))

      setError(response.error)

      if (response.data) {
        setResponse(response.data)
      }
    }

    return [response, error, request]
  }
}

export default ActivitiesService;
